import { useMemo } from 'react';

import { ControlFilterList, useControlFiltersValue } from 'States';

export function parseSingleUserValueFilter(filterList: ControlFilterList) {
  const { risk_owners: riskOwnerFilterList = [], verifiers: verifierFilterList = [] } = filterList;

  if (riskOwnerFilterList.length === 0 && verifierFilterList.length === 0) return;
  if (riskOwnerFilterList.length > 1 || verifierFilterList.length > 1) return;

  if (riskOwnerFilterList.length === 1 && verifierFilterList.length === 0) return riskOwnerFilterList[0];
  else if (riskOwnerFilterList.length === 0 && verifierFilterList.length === 1) return verifierFilterList[0];
  else if (riskOwnerFilterList[0].email === verifierFilterList[0].email) return riskOwnerFilterList[0];
}

export default function useSingleUserValueFilter() {
  const { filterList } = useControlFiltersValue();

  return useMemo(() => parseSingleUserValueFilter(filterList), [filterList]);
}
