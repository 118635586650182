import { Button, InputField, Link } from '@objectit/ui-lib-bootstrap';

import { LockIcon } from 'Components';

import useCreateTag from './CreateTag.controller';

interface CreateTagProps {
  isDisabled: boolean;
  handleSave: (newTag: string) => void;
  handleClose: () => void;
}

export default function CreateTag({ isDisabled, handleSave, handleClose }: CreateTagProps) {
  const { newTag, isCreatingTag, showCreateTag, hideCreateTag, handleNewTagChange, onSaveNewTag } = useCreateTag({ handleSave });
  const onCreateNewTagClick = isDisabled ? undefined : showCreateTag;
  const createNewTagClass = isDisabled ? 'link-disabled mb-1' : 'cursor-pointer mb-1';

  return (
    <div className="vstack gap-2 text-center">
      {isCreatingTag ? (
        <>
          <InputField name="create_tag" placeholder="Enter a new tag" value={newTag} onChange={handleNewTagChange} />
          <Button label="Save New Tag" color="success" onClick={onSaveNewTag} />
          <Button label="Cancel" color="white" onClick={hideCreateTag} />
        </>
      ) : (
        <>
          <span className={createNewTagClass}>
            {isDisabled && <LockIcon />}
            <Link label="Create New Tag" onClick={onCreateNewTagClick} />
          </span>
          <Button label="Close" color="white" onClick={handleClose} />
        </>
      )}
    </div>
  );
}
