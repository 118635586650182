import { atom, useRecoilState, useRecoilValue, AtomEffect, useResetRecoilState } from 'recoil';

import { clearPersistentState, getPersistentState, savePersistentState } from 'Utils';
import { UserInfo } from 'Types';

export interface ControlFilterList {
  control_statuses: string[];
  risk_statuses: string[];
  tags: string[];
  risk_owners?: UserInfo[];
  verifiers?: UserInfo[];
}

export interface ControlFilters {
  include_archive: boolean;
  search: string;
  filterList: ControlFilterList;
  page: number;
  sort_by: string;
}

export const FILTERS_STORE = 'control-filters';
export const DEFAULT_FILTERS = { page: 1, sort_by: '-created_at', filterList: {} } as ControlFilters;

export const storedFilters = () => getPersistentState(FILTERS_STORE);

const persistFilters: AtomEffect<ControlFilters> = ({ onSet }) => {
  onSet((filters, _, isReset) => {
    if (isReset) clearPersistentState(FILTERS_STORE);
    else savePersistentState(FILTERS_STORE, filters);
  });
};

const controlFilters = atom({
  key: 'controlFilters',
  default: (storedFilters() || DEFAULT_FILTERS) as ControlFilters,
  effects: [persistFilters],
});

export function useControlFiltersValue() {
  return useRecoilValue(controlFilters);
}

export function useControlFiltersState() {
  return useRecoilState(controlFilters);
}

export function useResetControlFiltersState() {
  return useResetRecoilState(controlFilters);
}
