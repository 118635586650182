import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { NotificationsProvider, useShowNotification } from '@objectit/ui-lib-bootstrap';
import { ApiService } from '@objectit/utils/dist/services/apiService';

function useNotificationsContainer() {
  const showNotification = useShowNotification();

  useEffect(() => {
    const interceptor = ApiService.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status !== 401) {
          showNotification({
            type: 'danger',
            title: error.response.statusText,
            message: error.message,
          });
        }
        return Promise.reject(error);
      }
    );
    return () => ApiService.interceptors.request.eject(interceptor);
  }, [showNotification]);
}

export default function NotificationsContainer() {
  useNotificationsContainer();

  return (
    <NotificationsProvider>
      <Outlet />
    </NotificationsProvider>
  );
}
