/* eslint-disable max-len */
import { ControlStatus } from 'Types';
import { CONTROL_STATUSES, DEFAULT_CONTROL_STATUS } from 'Utils';

interface ControlStatusProps {
  controlStatus: ControlStatus;
  size?: number | string;
}

export default function ControlStatusIcon(props: ControlStatusProps) {
  const { controlStatus = DEFAULT_CONTROL_STATUS, size = 36 } = props;
  const statusColor = CONTROL_STATUSES[controlStatus].color;
  const style = { width: size };

  return (
    <div className="d-flex position-relative" style={style}>
      <svg className="bi bi-c-circle-fill" width="100%" fill="currentColor" color={statusColor} viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM8.146 4.992c.961 0 1.641.633 1.729 1.512h1.295v-.088c-.094-1.518-1.348-2.572-3.03-2.572-2.068 0-3.269 1.377-3.269 3.638v1.073c0 2.267 1.178 3.603 3.27 3.603 1.675 0 2.93-1.02 3.029-2.467v-.093H9.875c-.088.832-.75 1.418-1.729 1.418-1.224 0-1.927-.891-1.927-2.461v-1.06c0-1.583.715-2.503 1.927-2.503Z" />
      </svg>
    </div>
  );
}
