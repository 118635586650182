import { useRef } from 'react';

export default function useScrollPosition() {
  const elementRef = useRef<HTMLDivElement | null>(null);

  function resetScroll() {
    elementRef.current?.scrollTo({ top: 0, left: 0 });
  }

  function getScrollPosition() {
    return {
      top: elementRef.current?.scrollTop || 0,
      left: elementRef.current?.scrollLeft || 0,
    };
  }

  return { elementRef, getScrollPosition, resetScroll };
}
