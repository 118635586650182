import { lazy, ReactNode, Suspense } from 'react';

import { InputField, Link, Select } from '@objectit/ui-lib-bootstrap';

import { Popover, Tooltip } from 'Components';
import { useIsMobile, useModalState, useSingleUserValueFilter } from 'Utils';
import { UserInfo } from 'Types';

import { sortByOptions, sortByOutstandingOptions } from './ControlSortByModal/ControlSortByModal.config';
import useControlFilters from './ControlFilters.controller';
import ControlFilterSelections from './ControlFilterSelections';

import './ControlSortAndFilter.scss';

const ControlFiltersModal = lazy(() => import('./ControlFiltersModal/ControlFiltersModal'));

interface ControlSortAndFilterProps {
  isLoading: boolean;
  currentControlCount: number;
  totalControlCount: number;
  tableTitle?: ReactNode;
  tableMiddleCaption?: ReactNode;
  actionButtons?: ReactNode;
}

type ModalTypes = 'CONTROL_FILTERS' | 'CONTROL_SORT_BY';

function showFiltersModal(showControlModal: (modalType: ModalTypes) => void) {
  return () => showControlModal('CONTROL_FILTERS');
}

function onToggleArchiveClick(isLoading: boolean, toggleArchive: () => void) {
  return () => !isLoading && toggleArchive();
}

function onSortbyChange(handleSortChange: (value: string) => void) {
  return (_: string, value: string | number) => handleSortChange(value as string);
}

function FilteredVerifierLabel({ verifier }: { verifier: UserInfo }) {
  if (!verifier) return null;

  const fullName = `${verifier.first_name} ${verifier.last_name}`;

  return (
    <Tooltip
      className="fw-normal"
      tooltip={
        <div className="text-nowrap text-center" style={{ width: 100 }}>
          {verifier.email}
        </div>
      }
    >
      {fullName} (...)
    </Tooltip>
  );
}

export default function ControlFilters(props: ControlSortAndFilterProps) {
  const { isLoading, currentControlCount, totalControlCount, tableTitle, tableMiddleCaption, actionButtons } = props;
  const { controlFilters, handleSearchChange, handleFilterListChange, handleSortChange, toggleArchive, clearFilterList } = useControlFilters();
  const { modalState, isModalOpen, showModal, closeModal } = useModalState<ModalTypes>();
  const { include_archive, search, filterList, sort_by } = controlFilters || {};
  const { risk_owners, verifiers } = filterList || {};
  const isMobile = useIsMobile();
  const filteredUser = useSingleUserValueFilter();
  const sortOptions = filteredUser ? sortByOptions.concat(sortByOutstandingOptions) : sortByOptions;

  return (
    <div className="px-3 pb-1 vstack gap-md-2">
      <div className="row">
        <div className="col-11 col-md-4">
          <InputField
            name="search_controls"
            placeholder="Search"
            value={search}
            onChange={(_, value) => handleSearchChange(value as string)}
            isClearable
          />
        </div>
        {isMobile ? (
          <div className="col-1 d-flex justify-content-end">
            <Popover
              content={
                <div className="p-3 border border-dark rounded">This is a mobile friendly view. To see more features, use a larger screen.</div>
              }
              placement="bottom"
              closeOnClickOutside
            >
              <i className="bi bi-question-circle-fill ms-1 text-info"></i>
            </Popover>
          </div>
        ) : (
          <>
            <div className="col col-md-2 hstack gap-3">
              <span className="cursor-pointer">
                <Link label="Filter" onClick={showFiltersModal(showModal)} />
              </span>
              <span className={isLoading ? 'cursor-default' : 'cursor-pointer'}>
                <Link label={include_archive ? 'Hide Archive' : 'Show Archive'} onClick={onToggleArchiveClick(isLoading, toggleArchive)} />
              </span>
            </div>
            <div className="col d-flex justify-content-end">{actionButtons}</div>
          </>
        )}
      </div>
      {isMobile ? (
        <div className="row">
          <div className="hstack flex-wrap gap-1 fs-smaller-80">
            <div>Filtered by:</div>
            <div className="hstack gap-1 badge bg-grey">
              <div>Risk Owner:</div>
              {risk_owners?.map((verifier) => (
                <FilteredVerifierLabel key={verifier.email} verifier={verifier} />
              ))}
            </div>
            <div className="hstack gap-1 badge bg-grey">
              <div>Verifiers:</div>
              {verifiers?.map((verifier) => (
                <FilteredVerifierLabel key={verifier.email} verifier={verifier} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <ControlFilterSelections filterList={filterList} handleFilterListChange={handleFilterListChange} clearFilterList={clearFilterList} />
          </div>
          <div className="row align-items-end">
            <div className="col">
              {tableTitle}
              <span className="fs-smaller-80">
                {currentControlCount} of {totalControlCount} Controls
              </span>
            </div>
            <div className="col">{tableMiddleCaption}</div>
            <div className="col d-flex justify-content-end">
              <div className="sort-by">
                <div>Sorted by:</div>
                <div className="sort-by-select">
                  <Select name="sort-by" label="" options={sortOptions} onChange={onSortbyChange(handleSortChange)} value={sort_by} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Suspense>{isModalOpen('CONTROL_FILTERS') && <ControlFiltersModal isOpen={modalState.isOpen} handleClose={closeModal} />}</Suspense>
    </div>
  );
}
