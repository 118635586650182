import { useLocation } from 'react-router-dom';

import { useAuthProvider } from '@objectit/auth-provider';
import { Breadcrumb, TopNav as UiLibTopNav, useSideNav } from '@objectit/ui-lib-bootstrap';

import { useResetControlFiltersState } from 'States';
import { useCurrentUser } from 'Utils';

import TenantSelect from './TenantSelect';
import './TopNav.css';

const PAGE_TITLES = {
  '/controls/(.*)/verification_history': [
    {
      label: 'Controls List',
      pathname: '/controls',
    },
    {
      label: 'Verification History',
    },
  ],
  '/controls': [
    {
      label: 'Controls List',
      pathname: '/controls',
    },
  ],
  '/status_history': [
    {
      label: 'Status History',
      pathname: '/status_history',
    },
  ],
};

type BreadcrumbItems = Parameters<typeof Breadcrumb>[0]['items'];

function useTopNav() {
  const { pathname } = useLocation();
  const { toggleSideNav } = useSideNav();
  const { getTokenClaims, logout } = useAuthProvider();
  const { isAdmin } = useCurrentUser();
  const resetFilterState = useResetControlFiltersState();

  const { email } = getTokenClaims() || {};

  const pageTitle = Object.entries(PAGE_TITLES).find(([pattern]) => new RegExp(pattern).test(pathname))?.[1] as BreadcrumbItems;

  function logoutUser() {
    resetFilterState();
    logout();
  }

  return {
    email,
    pageTitle,
    isAdmin,
    toggleSideNav,
    logout: logoutUser,
  };
}

export default function TopNav() {
  const { email, pageTitle, isAdmin, toggleSideNav, logout } = useTopNav();

  return (
    <UiLibTopNav
      header={
        <span className="top-nav-header fs-3 hstack">
          <i className="bi bi-list me-1 cursor-pointer p-1 fw-bold" onClick={toggleSideNav}></i>
          <span className="d-lfex text-white">
            <Breadcrumb items={pageTitle} />
          </span>
        </span>
      }
      email={email}
      navActionsOptions={[{ label: 'Logout', action: () => logout() }]}
      extraActions={
        <div className="hstack gap-3 z-index-2">
          <TenantSelect />
          <span className="text-white fw-bold">{isAdmin ? 'Admin User: ' : 'User:'}</span>
        </div>
      }
    />
  );
}
