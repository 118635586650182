import { useReducer } from 'react';

type ModalState<Types, Context> = {
  isOpen: boolean;
  modalType: Types | undefined;
  context?: Context;
};

type ModalActions<Types, Context> =
  | {
      type: 'OPEN';
      modalType: Types;
      context?: Context;
    }
  | {
      type: 'CLOSE';
    };

function modalReducer<Types, Context>() {
  return (state: ModalState<Types, Context>, action: ModalActions<Types, Context>) => {
    if (action.type === 'OPEN') {
      return {
        isOpen: true,
        modalType: action.modalType,
        context: action.context,
      };
    } else if (action.type === 'CLOSE') {
      return { isOpen: false, modalType: undefined, context: undefined };
    }
    return state;
  };
}

export default function useModalState<Types, Context = undefined>() {
  const [modalState, dispatch] = useReducer(modalReducer<Types, Context>(), { modalType: undefined, isOpen: false });

  function showModal(modalType: Types, context?: Context) {
    dispatch({ type: 'OPEN', modalType, context });
  }

  function closeModal() {
    dispatch({ type: 'CLOSE' });
  }

  function isModalOpen(modalType: Types) {
    return modalState.isOpen && modalState.modalType === modalType;
  }

  return { modalState, isModalOpen, showModal, closeModal };
}
