import { useCallback, useState } from 'react';

export default function useToggleState(initialValue?: boolean) {
  const [state, setState] = useState<boolean>(initialValue ?? false);

  const toggleState = useCallback(() => {
    setState(!state);
  }, [state]);

  function toggleOn() {
    setState(true);
  }

  function toggleOff() {
    setState(false);
  }

  return { isToggled: state, toggleState, toggleOn, toggleOff };
}
