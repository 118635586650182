import ReactMoment from 'react-moment';

interface DateDisplayProps {
  date: string | null | undefined;
  format?: string;
  className?: string;
}

export default function DateDisplay(props: DateDisplayProps) {
  const { date, format, className } = props;

  return date ? (
    <span className={className}>
      <ReactMoment format={format || 'DD-MMM-YYYY'}>{date}</ReactMoment>
    </span>
  ) : null;
}
