import { ChangeEvent, ClipboardEvent, FocusEvent, KeyboardEvent, useEffect, useRef } from 'react';
import linkifyHtml from 'linkify-html';
import { position } from 'caret-pos';

import { useUndoRedo, useToggleState } from 'Utils';

const LINKIFY_OPTIONS = { defaultProtocol: 'https', attributes: { rel: 'noopener', target: '_blank', class: 'likified-link' } };

interface UndoData {
  pos: number;
  value: string;
}

interface AutoLinkTextareaProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

export default function AutoLinkTextarea(props: AutoLinkTextareaProps) {
  const { name, value, onChange, label, placeholder, disabled } = props;
  const caretPosition = useRef<number>(0);
  const editorRef = useRef<HTMLDivElement>(null);
  const editorState = useToggleState(false);
  const undoRedo = useUndoRedo<UndoData>();
  const isLastEventUndoable = useRef(false);

  useEffect(() => {
    if (editorRef.current && editorRef.current === document.activeElement) {
      position(editorRef.current, caretPosition.current);
      if (isLastEventUndoable.current) undoRedo.push({ pos: caretPosition.current, value: editorRef.current.innerText });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function onInputEvent(event: ChangeEvent<HTMLDivElement>) {
    onChange(name, event.target.innerText);
  }

  function onKeyDownEvent(event: KeyboardEvent<HTMLDivElement>) {
    if (event.ctrlKey && event.key.toUpperCase() === 'Z') {
      let currentValue;

      if (event.shiftKey) {
        currentValue = undoRedo.redo();
      } else {
        currentValue = undoRedo.undo();
      }

      if (currentValue) {
        caretPosition.current = currentValue.pos;
        onChange(name, currentValue.value);
      }
      return;
    }

    const { pos } = position(event.target);

    // highlighted texts
    const selection = window.getSelection();
    const { startOffset = 0, endOffset = 0 } = selection?.getRangeAt(0) || {};
    const selectedTexts = endOffset - startOffset;

    isLastEventUndoable.current = true;

    switch (event.key) {
      case 'Delete':
        caretPosition.current = pos;
        if (selectedTexts >= 1) caretPosition.current = caretPosition.current - (selectedTexts - 1) - 1;
        break;
      case 'Backspace':
        caretPosition.current = pos - 1;
        if (selectedTexts >= 1) caretPosition.current = caretPosition.current - (selectedTexts - 1);
        break;
      default:
        caretPosition.current = pos + 1;
        if (selectedTexts >= 1) caretPosition.current = caretPosition.current - selectedTexts;
        isLastEventUndoable.current = false;
        break;
    }
  }

  function onClickEvent() {
    // delays make sure the editor is already in the editable state before setting it to a focused state
    // and also make the links act as links when clicked or hover onto.
    setTimeout(() => editorState.toggleOn(), 10);
    setTimeout(() => editorRef.current?.focus(), 20);
  }

  function onPasteEvent(event: ClipboardEvent<HTMLDivElement>) {
    const pastedText = event.clipboardData.getData('Text');
    caretPosition.current = caretPosition.current + pastedText.length - 1;
    isLastEventUndoable.current = true;
  }

  function onFocusEvent(event: FocusEvent<HTMLDivElement>) {
    // initialize undo stack on first focus of textarea,
    // because there is delay of 'value' during mount when the 'value' is dependent on backend/async response
    if (undoRedo.stack.length === 0) {
      const { pos } = position(event.target);
      undoRedo.push({ pos, value });
    }
  }

  function onBlurEvent(event: FocusEvent<HTMLDivElement>) {
    editorState.toggleOff();

    if (undoRedo.current?.value !== value) {
      const { pos } = position(event.target);
      undoRedo.push({ pos, value });
    }
  }

  const linkifiedText = linkifyHtml(value, LINKIFY_OPTIONS);

  return (
    <>
      {label?.trim() && <label className="form-label fw-bolder text-dark mb-0">{label}</label>}
      <div
        ref={editorRef}
        className="form-control auto-link-textarea"
        dangerouslySetInnerHTML={{ __html: linkifiedText }}
        contentEditable={!disabled && editorState.isToggled}
        onClick={onClickEvent}
        onInput={onInputEvent}
        onKeyDown={onKeyDownEvent}
        onPaste={onPasteEvent}
        onFocus={onFocusEvent}
        onBlur={onBlurEvent}
        placeholder={placeholder}
      ></div>
    </>
  );
}
