interface ControlFilterLabelProps {
  label: string;
  items: string[];
}

export default function ControlFilterLabel({ label, items }: ControlFilterLabelProps) {
  const lastIndex = items.length - 1;

  return (
    <span>
      <b>{label}:&nbsp;</b>
      {items.map((item, index) => (
        <span key={item}>
          {item}
          {index < lastIndex && <span className="control-filter-or"> or </span>}
        </span>
      ))}
    </span>
  );
}
