export function getPersistentState(key: string) {
  const data = localStorage.getItem(key);

  if (!data) return;

  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}

export function savePersistentState(key: string, data: unknown) {
  if (typeof data === 'string') {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
}

export function clearPersistentState(key: string) {
  localStorage.removeItem(key);
}
