import { ChangeEvent, ReactNode, useId } from 'react';
import { MDBCheckbox } from 'mdb-react-ui-kit';

interface CheckboxProps {
  label: ReactNode;
  checked: boolean;
  disabled?: boolean;
  labelClass?: string;
  onChange?: (checked: boolean) => void;
}

export default function Checkbox(props: CheckboxProps) {
  const { labelClass, onChange, ...config } = props;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.checked);
  }

  return <MDBCheckbox {...config} id={useId()} onChange={handleChange} labelClass={labelClass} />;
}
