import Tooltip from './Tooltip';

interface TruncateEmailProps {
  email: string;
  maxCharacters: number;
}

function truncateEmail(email: string, maxCharacters: number) {
  if (email.length <= maxCharacters) return email;

  const elipses = '...';
  const truncatedDomain = `@${elipses}`;
  const [emailName] = email.split('@');

  if (emailName.length + truncatedDomain.length <= maxCharacters) return `${emailName}${truncatedDomain}`;

  const truncatedEmail = emailName.substring(0, maxCharacters - truncatedDomain.length - elipses.length);
  return `${truncatedEmail}${elipses}${truncatedDomain}`;
}

export default function TruncateEmail(props: TruncateEmailProps) {
  const { email, maxCharacters } = props;
  const label = truncateEmail(email, maxCharacters);

  return (
    <Tooltip
      className="fw-normal fs-smaller-80 text-wrap"
      tag="span"
      tooltip={
        <div className="text-wrap text-center" style={{ width: 100 }}>
          {email}
        </div>
      }
    >
      {label}
    </Tooltip>
  );
}
