import { useState } from 'react';
import { useToggleState } from 'Utils';

interface UseCreateTagProps {
  handleSave: (newTag: string) => void;
}

export default function useCreateTag({ handleSave }: UseCreateTagProps) {
  const { isToggled: isCreatingTag, toggleOn: showCreateTag, toggleOff: hideCreateTag } = useToggleState();
  const [newTag, setNewTag] = useState('');

  function handleNewTagChange(_: string, tag: string | number) {
    setNewTag((tag as string).trimStart());
  }

  function onSaveNewTag() {
    handleSave(newTag);
    setNewTag('');
    hideCreateTag();
  }

  return {
    newTag,
    isCreatingTag,
    showCreateTag,
    hideCreateTag,
    handleNewTagChange,
    onSaveNewTag,
  };
}
