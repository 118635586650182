import { ReactNode, useEffect } from 'react';

import { Spinner } from '@objectit/ui-lib-bootstrap';

import { useOnElementVisible, useScrollPosition } from 'Utils';

import './InfiniteScroll.scss';
import { Callback } from 'src/utils/useOnElementVisible';

type ScrollVariant = 'vertical-scroll' | 'horizontal-scroll' | 'both-scroll';

interface DirectionalBoolean {
  horizontal: boolean;
  vertical: boolean;
}
interface DirectionalCallback {
  horizontal: Callback;
  vertical: Callback;
}

interface DirectionalPage {
  horizontal: number;
  vertical: number;
}

interface InfiniteScrollProps<T> {
  children: ReactNode;
  variant?: ScrollVariant;
  width?: number | string;
  height?: number | string;
  loaderType?: 'last' | 'cover';
  currentPage?: T extends 'both-scroll' ? Partial<DirectionalPage> : number;
  isLoading?: T extends 'both-scroll' ? Partial<DirectionalBoolean> : boolean;
  hasMore?: T extends 'both-scroll' ? Partial<DirectionalBoolean> : boolean;
  loadMore?: T extends 'both-scroll' ? Partial<DirectionalCallback> : Callback;
}

type PointerEvent = 'none' | 'auto';

function useInfiniteScroll<T extends InfiniteScrollProps<T['variant']>>(props: T) {
  const { loaderType = 'last', variant = 'vertical-scroll', hasMore, loadMore } = props;
  const { elementRef: containerElementRef, getScrollPosition, resetScroll } = useScrollPosition();

  const scrollPosition = getScrollPosition();
  let isLoading = props.isLoading as boolean;
  let horizontalScrollCalback;
  let verticalScrollCalback;

  if (variant === 'both-scroll') {
    const loadingProps = props.isLoading as DirectionalBoolean;
    isLoading = loadingProps.horizontal || loadingProps.vertical;

    if ((hasMore as DirectionalBoolean).horizontal) {
      horizontalScrollCalback = (loadMore as DirectionalCallback)?.horizontal;
    }

    if ((hasMore as DirectionalBoolean).vertical) {
      verticalScrollCalback = (loadMore as DirectionalCallback)?.vertical;
    }
  } else if (variant === 'vertical-scroll') {
    verticalScrollCalback = loadMore;
  } else if (variant === 'horizontal-scroll') {
    horizontalScrollCalback = loadMore;
  }

  const horizontalScrollEndElementRef = useOnElementVisible(horizontalScrollCalback as Callback);
  const verticalScrollEndElementRef = useOnElementVisible(verticalScrollCalback as Callback);

  const loaderClass = loaderType === 'cover' ? 'loader-cover' : 'text-center';
  const pointerEvents: PointerEvent = isLoading && loaderType === 'cover' ? 'none' : 'auto'; // prevent scrolling while loading

  useEffect(() => {
    if (!props.currentPage) return;

    if (variant === 'both-scroll') {
      const currentPage = props.currentPage as DirectionalPage;

      if (currentPage?.horizontal === 1 && currentPage?.vertical === 1) {
        resetScroll();
      }
    } else {
      const currentPage = props.currentPage as number;

      if (currentPage === 1) {
        resetScroll();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage]);

  return { isLoading, loaderClass, pointerEvents, containerElementRef, horizontalScrollEndElementRef, verticalScrollEndElementRef, scrollPosition };
}

export default function InfiniteScroll<T extends InfiniteScrollProps<T['variant']>>(props: T) {
  const { children, height = '100%', width = '100%', variant = 'vertical-scroll' } = props;
  const { isLoading, loaderClass, pointerEvents, containerElementRef, horizontalScrollEndElementRef, verticalScrollEndElementRef, scrollPosition } =
    useInfiniteScroll(props);

  const style = {
    'horizontal-scroll': { maxWidth: width },
    'vertical-scroll': { maxHeight: height },
    'both-scroll': { maxWidth: width, maxHeight: height },
  }[variant];

  return (
    <div ref={containerElementRef} className={`infinite-scroll ${variant}`} style={{ ...style, pointerEvents }}>
      {variant === 'vertical-scroll' ? (
        <>
          {children}
          <div className="scroll-end-element" ref={verticalScrollEndElementRef}></div>
        </>
      ) : (
        <>
          <div>
            {children}
            <div className="scroll-end-element" ref={verticalScrollEndElementRef}></div>
          </div>
          <div className="scroll-end-element" ref={horizontalScrollEndElementRef}></div>
        </>
      )}
      {isLoading && (
        <div className={loaderClass} style={scrollPosition}>
          <div className="loader-spinner">
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
}
