import { RemoveIcon, SearchInput } from 'Components';
import useTagsContent from './TagContent.controller';
import TagList from './TagList';
import CreateTag from './CreateTag/CreateTag';
import { TagsProps } from '../Tags';

interface TagContentProps extends TagsProps {
  disableCreate: boolean;
  handleClose: () => void;
}

export default function TagContent({ tagsSelection, disableCreate, handleTagsChange, handleClose }: TagContentProps) {
  const { tagList, hasMore, isPending, handleTagSelection, handleSaveNewTag, handleTagSearchChange, loadMore } = useTagsContent({
    tagsSelection,
    handleTagsChange,
  });

  return (
    <div className="p-3 shadow border border-dark rounded">
      <div className="position-relative mb-2">
        <strong>Tags</strong>
        <div className="position-absolute top-0 end-0">
          <RemoveIcon handleRemove={handleClose} />
        </div>
      </div>
      <SearchInput name="search_tags" placeholder="Search tags" onChange={handleTagSearchChange} />
      <hr />
      <TagList
        tagList={tagList}
        tagsSelection={tagsSelection}
        isLoading={isPending}
        hasMore={hasMore}
        loadMore={loadMore}
        handleTagSelection={handleTagSelection}
      />
      <hr />
      <CreateTag isDisabled={disableCreate} handleSave={handleSaveNewTag} handleClose={handleClose} />
    </div>
  );
}
