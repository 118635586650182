import { useCallback, useEffect, useRef } from 'react';

import { useFetchControls } from 'Services';
import { ControlFilterList, ControlFilters, DEFAULT_FILTERS, storedFilters, useControlFiltersState } from 'States';
import { ControlResponse, ControlRow, UserInfo } from 'Types';
import { useCurrentUser, useDebounce } from 'Utils';

import useControlsReducer from './Controls.reducer';

function parseControlsResponse(response: ControlResponse[]) {
  if (!response) return [];

  return response.map(({ id, attributes }: ControlResponse) => ({ id, ...attributes })) as ControlRow[];
}

export default function useFetchControlsList() {
  const { debounce } = useDebounce(800);
  const currentUser = useCurrentUser() as unknown as UserInfo;
  const previousFilters = useRef<ControlFilters | null>(null);
  const [controlFilters, setControlFilters] = useControlFiltersState();
  const { response, isPending, isSuccess, pagination, sendRequest } = useFetchControls();
  const { controls, total, setControls, addControls, clearControls } = useControlsReducer();
  const pages = pagination?.pages || 1;

  useEffect(() => {
    if (!currentUser.email) return;

    let currentFilters = { ...controlFilters, page: 1 };

    if (!storedFilters()) {
      currentFilters = {
        ...DEFAULT_FILTERS,
        filterList: { verifiers: [currentUser] } as ControlFilterList,
      };
    }

    setControlFilters(currentFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.email]);

  const fetchControls = useCallback(() => {
    sendRequest(controlFilters);
  }, [sendRequest, controlFilters]);

  useEffect(() => {
    if (controlFilters.page > pages) return;

    let fetchImmediately = false;

    // Refetch controls immidiately when scrolled to the end, sort changes and show archive is toggled
    if (previousFilters.current) {
      const oldFilters = previousFilters.current;
      const isPageChanged = oldFilters.page != controlFilters.page;
      const isArchivedToggledd = oldFilters.include_archive != controlFilters.include_archive;
      const isSortChanged = oldFilters.sort_by != controlFilters.sort_by;

      fetchImmediately = isPageChanged || isArchivedToggledd || isSortChanged;
    }

    if (fetchImmediately) {
      fetchControls();
    } else {
      // Refetch controls with new filter from search and filterList
      // with debouncing to prevent multiple request at the same time selecting filters fast and searching
      debounce(fetchControls);
    }

    previousFilters.current = { ...controlFilters };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlFilters, debounce]);

  useEffect(() => {
    if (!isSuccess) return;

    if (response) {
      const newControls = parseControlsResponse(response as ControlResponse[]);
      if (controlFilters.page === 1) {
        setControls(newControls, pagination?.count || 1);
      } else {
        addControls(newControls);
      }
    } else {
      clearControls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isSuccess]);

  const loadMoreControls = useCallback(() => {
    if (controlFilters.page >= pages) return;

    setControlFilters((sortAndFilters) => ({ ...sortAndFilters, page: controlFilters.page + 1 }));
  }, [controlFilters.page, pages, setControlFilters]);

  const hasMoreControl = controlFilters.page < pages;
  const controlList = controlFilters.page === 1 && isPending ? [] : controls;

  const reloadControlList = useCallback(() => {
    if (controlFilters.page === 1) {
      fetchControls();
    } else {
      setControlFilters((sortAndFilters) => ({ ...sortAndFilters, page: 1 }));
    }
  }, [controlFilters.page, fetchControls, setControlFilters]);

  return {
    isPending,
    controls: controlList,
    hasMoreControl,
    currentPage: controlFilters.page,
    totalControlCount: total,
    loadMoreControls,
    reloadControlList,
  };
}
