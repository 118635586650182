import { useEffect } from 'react';

import { useFetchControlStatusCounts } from 'Services';
import { useControlFiltersValue } from 'States';
import { ControlStatusCount, ControlStatusesCountResponse, RiskStatusCount } from 'Types';
import { CONTROL_STATUSES, RISK_STATUSES } from 'Utils';

import './Dashboard.css';

function parseControlStatuses(control_statuses: ControlStatusCount[] = []) {
  return Object.entries(CONTROL_STATUSES).map(([value, { label }]) => {
    const controlStatus = control_statuses?.find((item) => item.status === Number(value));

    return { label, value: controlStatus?.total || 0 };
  });
}

function parseRisksStatuses(risk_statuses?: RiskStatusCount[]) {
  return Object.entries(RISK_STATUSES).map(([value, { label }]) => {
    const riskStatus = risk_statuses?.find((item) => item.status === Number(value));

    return { label, value: riskStatus?.total || 0 };
  });
}

export default function useDashboard() {
  const controlFilters = useControlFiltersValue();
  const { response, sendRequest } = useFetchControlStatusCounts();
  const { attributes: { control_statuses, risk_statuses } = {} } = (response || {}) as ControlStatusesCountResponse;
  const controlStatuses = parseControlStatuses(control_statuses);
  const riskStatuses = parseRisksStatuses(risk_statuses);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sort_by, page, ...filters } = controlFilters;
    sendRequest(filters);
  }, [controlFilters, sendRequest]);

  return {
    controlStatuses,
    riskStatuses,
  };
}
