import { Suspense } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { Spinner } from '@objectit/ui-lib-bootstrap';

import { PageLayout, PrivatePageContainer, NotificationsContainer } from 'Components';

import {
  Home,
  LoginPage,
  CompanyRegistration,
  Controls,
  StatusHistory,
  VerifyAccount,
  VerificationHistory,
  ActivationLinkExpired,
  MaxEmailResendReached,
  RegistrationExpired,
  PasswordReset,
  PasswordChange
} from './pages';

function App(): JSX.Element {
  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <div className="vw-100 vh-100 d-flex justify-content-center align-items-center ">
            <Spinner />
          </div>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NotificationsContainer />}>
              <Route path="/" element={<PrivatePageContainer />}>
                <Route path="/auth_callback" element={<Navigate to="/login" />} />
                <Route path="/verify_account" element={<VerifyAccount />} />
                <Route path="/login" element={<LoginPage />} />

                <Route path="/" element={<PageLayout />}>
                  <Route path="/controls/:id/verification_history" element={<VerificationHistory />} />
                  <Route path="/controls" element={<Controls />} />
                  <Route path="/status_history" element={<StatusHistory />} />
                  <Route path="/*" element={<Navigate to="/controls" />} />
                </Route>
              </Route>

              <Route path="register" element={<CompanyRegistration />} />
              <Route path="activation_link_expired" element={<ActivationLinkExpired />} />
              <Route path="max_email_resend_reached" element={<MaxEmailResendReached />} />
              <Route path="registration_expired" element={<RegistrationExpired />} />
              <Route path="password_reset" element={<PasswordReset />} />
              <Route path="password_change/:password_reset_id" element={<PasswordChange />} />
              <Route index element={<Home />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </RecoilRoot>
  );
}

export default App;
