import useApiRequest from './Api.services';

export function useRegisterCompany() {
  return useApiRequest({ url: '/register/', method: 'POST' });
}

export function useVerifyAccount(onSuccess: () => void) {
  return useApiRequest({ url: `/users/verify_or_update`, method: 'PUT', onSuccess });
}

export function useSendPasswordReset() {
  return useApiRequest({ url: '/password_reset/', method: 'POST' });
}

export function usePasswordChange() {
  return useApiRequest({ url: '/password_reset/set_password', method: 'POST' });
}

export function useValidatePasswordChangeLink(uid: string) {
  return useApiRequest({ url: `/password_reset/validate?uid=${uid}`, method: 'GET', onMount: true });
}
