import { Checkbox, InfiniteScroll } from 'Components';

interface TagListProps {
  tagList: string[];
  tagsSelection: string[];
  isLoading: boolean;
  hasMore: boolean;
  loadMore: () => void;
  handleTagSelection: (tag: string) => () => void;
}

export default function TagList(props: TagListProps) {
  const { tagList, tagsSelection = [], isLoading, hasMore, loadMore, handleTagSelection } = props;

  return (
    <InfiniteScroll isLoading={isLoading} hasMore={hasMore} loadMore={loadMore} height={208}>
      {tagList?.map((tag) => (
        <Checkbox key={tag} label={tag} checked={tagsSelection.includes(tag)} onChange={handleTagSelection(tag)} />
      ))}
    </InfiniteScroll>
  );
}
