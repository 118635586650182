import { useEffect, useRef } from 'react';

export type Callback = (() => void) | undefined;

export default function useOnElementVisible(callback: Callback) {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];

        if (target.isIntersecting) {
          callback?.();
        }
      },
      { threshold: 0.5 }
    );

    const target = elementRef.current;

    if (target) {
      observer.observe(target);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      return () => observer.unobserve(target);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, elementRef.current]);

  return elementRef;
}
