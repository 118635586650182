import { lazy } from 'react';

export const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
export const CompanyRegistration = lazy(() => import('./CompanyRegistration/CompanyRegistration'));
export const Home = lazy(() => import('./Home'));
export const Controls = lazy(() => import('./Controls/Controls'));
export const StatusHistory = lazy(() => import('./StatusHistory/StatusHistory'));
export const VerifyAccount = lazy(() => import('./VerifyAccount'));
export const VerificationHistory = lazy(() => import('./VerificationHistory/VerificationHistory'));
export const ActivationLinkExpired = lazy(() => import('./ActivationLinkExpired'));
export const MaxEmailResendReached = lazy(() => import('./MaxEmailResendReached'));
export const RegistrationExpired = lazy(() => import('./RegistrationExpired'));
export const ModifyStatusComponent = lazy(() => import('./Settings/ModifyStatusComponentModal/ModifyStatusComponentModal'));
export const PasswordReset = lazy(() => import('./Password/Reset'));
export const PasswordChange = lazy(() => import('./Password/Change'));
