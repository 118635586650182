import moment from 'moment';

const AU_DATE_FORMAT = 'DD MMM YYYY';

export function dateToday() {
  return moment().format(AU_DATE_FORMAT);
}

export function dateToAuFormat(date?: string) {
  if (!date) return;

  return moment(date).format(AU_DATE_FORMAT);
}

export function isDatesEqual(date1?: string, date2?: string) {
  if (!date1 || !date2) return false;

  return dateToAuFormat(date1) === dateToAuFormat(date2);
}
