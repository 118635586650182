import { useReducer } from 'react';

import { ControlRow } from 'Types';

interface ControlsState {
  controls: ControlRow[];
  total: number;
}

export type ControlsReducerAction =
  | {
      type: 'SET_CONTROL';
      data: ControlsState;
    }
  | { type: 'APPEND_CONTROL'; data: ControlsState['controls'] }
  | { type: 'CLEAR_CONTROL' };

export function controlsReducer(controlState: ControlsState, action: ControlsReducerAction) {
  if (action.type === 'SET_CONTROL') return action.data;

  if (action.type === 'APPEND_CONTROL') {
    const newControls = action.data;
    const controls = controlState.controls.concat(newControls);
    return { ...controlState, controls };
  }

  if (action.type === 'CLEAR_CONTROL') return { ...controlState, controls: [] };

  return controlState;
}

export default function useControlsReducer() {
  const [{ controls, total }, dispatch] = useReducer(controlsReducer, { controls: [], total: 1 } as ControlsState);

  function setControls(newControls: ControlRow[], controlsCount: number) {
    dispatch({ type: 'SET_CONTROL', data: { controls: newControls, total: controlsCount } });
  }

  function addControls(newControls: ControlRow[]) {
    dispatch({ type: 'APPEND_CONTROL', data: newControls });
  }

  function clearControls() {
    dispatch({ type: 'CLEAR_CONTROL' });
  }

  return { controls, total, setControls, addControls, clearControls };
}
