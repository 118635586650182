import linkifyHtml from 'linkify-html';

const LINKIFY_OPTIONS = { defaultProtocol: 'https', attributes: { rel: 'noopener', target: '_blank', class: 'likified-link' } };

interface LinkifiedTextProps {
  text: string
}

export default function LinkifiedText(props: LinkifiedTextProps) {
  const linkifiedText = linkifyHtml(props.text, LINKIFY_OPTIONS);
  return <span dangerouslySetInnerHTML={{ __html: linkifiedText }} style={{ whiteSpace: 'pre-wrap' }}></span>;
}
