import { Outlet } from 'react-router-dom';

import AuthProvider from '@objectit/auth-provider';

import ErrorInterceptor from './ErrorInterceptor';

export default function PrivatePageContainer() {
  return (
    <AuthProvider>
      <ErrorInterceptor />
      <div className="vw-100 vh-100 m-0 p-0 overflow-auto bg-light">
        <Outlet />
      </div>
    </AuthProvider>
  );
}
