import { useEffect, useRef } from 'react';

type TargetRef = HTMLDivElement;

export default function useClickOutside(callback: () => void) {
  const targetRef = useRef<TargetRef>(null);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (targetRef.current && !targetRef.current.contains?.(event.target as TargetRef)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return targetRef;
}
