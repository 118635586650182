import { StatusCategoriesState } from 'Types';
import { atom, useRecoilState } from 'recoil';

export const statusCategories = atom({
  key: 'statusCategories',
  default: [] as StatusCategoriesState[],
});

export function useStatusCategoriesState() {
  return useRecoilState(statusCategories);
}
