import { Badge } from '@objectit/ui-lib-bootstrap';

import { Popover } from 'Components';
import { useOnClickOutside } from 'Utils';

import TagContent from './TagContent/TagsContent';
import useTags from './Tags.controller';

export interface TagsProps {
  tagsSelection: string[];
  handleTagsChange: (tags: string[]) => void;
  disableCreate?: boolean;
}

export default function Tags({ tagsSelection, disableCreate, handleTagsChange }: TagsProps) {
  const { tags, isToggled, toggleOn, toggleOff } = useTags(tagsSelection);
  const elementRef = useOnClickOutside(toggleOff);

  return (
    <div>
      <Popover
        content={
          <div ref={elementRef}>
            <TagContent
              disableCreate={disableCreate || false}
              handleClose={toggleOff}
              tagsSelection={tagsSelection}
              handleTagsChange={handleTagsChange}
            />
          </div>
        }
        placement="top-start"
        isOpen={isToggled}
        onClick={toggleOn}
      >
        <a className="text-decoration-underline">Tags</a>
      </Popover>
      <div className="hstack gap-1 flex-wrap mt-1">
        {tags?.map((tag) => (
          <Badge key={tag} label={tag} color="grey" />
        ))}
      </div>
    </div>
  );
}
