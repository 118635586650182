import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiService } from '@objectit/utils/dist/services/apiService';
import { getAuthSession } from '@objectit/utils';

const ERRORS = {
  INACTIVE_USER: { code: 'user_inactive' },
  UNVERIFIED_USER: { details: 'UserUnverifiedPermissionDenied', redirect: '/verify_account' },
};

function inactiveUserRedirect() {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const token = getAuthSession();
  return new URL(`users/activate?token=${token?.refresh}`, apiUrl);
}

export default function ErrorInterceptor() {
  const navigateTo = useNavigate();

  useEffect(() => {
    const interceptor = ApiService.interceptors.response.use(
      (response) => response,
      (error) => {
        const details = error.response?.data?.errors?.error?.details || {};

        if (details.code === ERRORS.INACTIVE_USER.code) {
          const redirectUrl = inactiveUserRedirect();
          location.replace(redirectUrl);
        } else if (details === ERRORS.UNVERIFIED_USER.details) {
          navigateTo(ERRORS.UNVERIFIED_USER.redirect);
        }

        return Promise.reject(error);
      }
    );
    return () => ApiService.interceptors.request.eject(interceptor);
  }, [navigateTo]);

  return null;
}
