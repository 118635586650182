import { useEffect, useState } from 'react';

import { InputField } from '@objectit/ui-lib-bootstrap';

import { useDebounce } from 'Utils';

type InputFieldProps = Parameters<typeof InputField>[0];

interface SearchInputProps extends Omit<InputFieldProps, 'onChange' | 'value'> {
  onChange: (name: string, value: string) => void;
  debounceMsTime?: number;
}

export default function SearchInput(props: SearchInputProps) {
  const { debounceMsTime, name, onChange } = props;
  const { debounce } = useDebounce(debounceMsTime || 800);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  useEffect(() => {
    if (searchTerm === undefined) return;

    debounce(() => onChange(name, (searchTerm as string).trimStart()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, searchTerm]);

  function onInputChange(_: string, search: string | number) {
    setSearchTerm((search as string).trimStart());
  }

  return <InputField {...props} name={name} value={searchTerm || ''} onChange={onInputChange} />;
}
