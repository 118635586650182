import { useCallback, useEffect } from 'react';

import { ControlFilterList } from 'States';
import { UserInfo } from 'Types';
import { CONTROL_STATUSES, RISK_STATUSES } from 'Utils';

import useApiRequest, { RequestConfig } from '../Api.services';

function statusesToMap(statuses: typeof CONTROL_STATUSES | typeof RISK_STATUSES) {
  const statusesEntries = Object.entries(statuses).map(([value, { label }]) => [label, value]);
  return Object.fromEntries(statusesEntries);
}

const controlStatusMap = statusesToMap(CONTROL_STATUSES);
const riskStatusMap = statusesToMap(RISK_STATUSES);

function addFilterListToQueryParams(params: URLSearchParams, filters: ControlFilterList) {
  filters?.risk_owners?.forEach((risk_owner: UserInfo) => {
    params.append('risk_owners', risk_owner.email);
  });
  filters?.verifiers?.forEach((verifier: UserInfo) => {
    params.append('verifiers', verifier.email);
  });
  filters?.control_statuses?.forEach((control_status: string) => {
    params.append('control_status', controlStatusMap[control_status]);
  });
  filters?.risk_statuses?.forEach((risk_status: string) => {
    params.append('risk_status', riskStatusMap[risk_status]);
  });
  filters?.tags?.forEach((tag: string) => {
    params.append('tags', tag);
  });
}

function payloadToQueryParams(payload: object = {}) {
  const params = new URLSearchParams();

  Object.entries(payload).forEach(([key, value]) => {
    if (key === 'filterList') {
      addFilterListToQueryParams(params, value);
    } else if (key === 'page') {
      params.append('page[number]', value as string);
    } else {
      params.append(key, value as string);
    }
  });

  return params;
}

export function useControlRequest({ onMount, ...config }: RequestConfig) {
  const { sendRequest, ...request } = useApiRequest(config);

  const sendControlRequest = useCallback(
    (payload: object = {}) => {
      let params = payload;
      if (config.method === 'GET') {
        params = payloadToQueryParams(payload);
      }
      sendRequest(params);
    },
    [config.method, sendRequest]
  );

  useEffect(() => {
    if (onMount) {
      sendControlRequest();
    }
  }, [onMount, sendControlRequest]);

  return {
    ...request,
    sendRequest: sendControlRequest,
  };
}

export function useFetchControls(onMount?: boolean) {
  return useControlRequest({ url: '/controls/', method: 'GET', onMount });
}

export function useFetchControl(controlId: string, onMount?: boolean) {
  return useControlRequest({ url: `/controls/${controlId}/`, method: 'GET', onMount });
}

export function useCreateControl(onSuccess: () => void) {
  return useControlRequest({ url: '/controls/', method: 'POST', onSuccess });
}

export function useUpdateControl(onSuccess: () => void, controlId: string) {
  return useControlRequest({ url: `/controls/${controlId}/`, method: 'PUT', onSuccess });
}

export function useFetchVerifiersList() {
  return useControlRequest({ url: `/users/`, method: 'GET' });
}

export function useSendVerificationRequest(onSuccess: () => void, controlId: string) {
  return useControlRequest({ url: `/controls/${controlId}/verification_request`, method: 'POST', onSuccess });
}

export function useArchiveControl(onSuccess: () => void, controlId: string) {
  return useControlRequest({ url: `/controls/${controlId}/archive/`, method: 'PUT', onSuccess });
}

export function useUnarchiveControl(onSuccess: () => void, controlId: string) {
  return useControlRequest({ url: `/controls/${controlId}/unarchive/`, method: 'PUT', onSuccess });
}

export function useSubmitVerificationResponse(onSuccess: () => void, controlId: string) {
  return useControlRequest({ url: `/controls/${controlId}/verification_response`, method: 'POST', onSuccess });
}

export function useFetchControlStatusCounts(onMount?: boolean) {
  return useControlRequest({ url: '/controls/stats', method: 'GET', onMount });
}

export function useFetchRequests(controlId: string, onMount?: boolean) {
  return useControlRequest({ url: `/controls/${controlId}/verification_requests`, method: 'GET', onMount });
}

export function useFetchStatusHistory(onMount?: boolean) {
  return useControlRequest({ url: '/controls/status_history', method: 'GET', onMount });
}
