import { useState, useEffect } from 'react';
import { usePaginatedFetch } from 'Utils';
import { useFetchTagList } from 'Services';
import { useNewTagsState } from '../Tags.states';
import { TagsProps } from '../Tags';

export function useTagList() {
  const [tagList, setTagList] = useState<string[]>([]);
  const [tagSearch, setTagSearch] = useState('');
  const { response, isPending, isSuccess, pagination, sendRequest } = useFetchTagList();
  const { data, page, hasMore, loadMore, resetPagination } = usePaginatedFetch({
    response,
    pagination,
    isSuccess,
    dataParser: (resp) => (resp as unknown as { tags: string[] }).tags,
  });

  useEffect(() => {
    sendRequest({ group: 'control', search: tagSearch, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setTagList(data as string[]);
  }, [data]);

  function handleTagSearchChange(_: string, search: string) {
    resetPagination();
    setTagSearch(search);
    sendRequest({ group: 'control', search, page: 1 });
  }

  return {
    tagList,
    hasMore,
    tagSearch,
    isPending,
    loadMore,
    handleTagSearchChange,
  };
}

export default function useTagsContent(props: TagsProps) {
  const { tagsSelection = [], handleTagsChange } = props;
  const { tagList, hasMore, tagSearch, isPending, loadMore, handleTagSearchChange } = useTagList();
  const [newTags, setNewTags] = useNewTagsState();

  function handleTagSelection(tag: string) {
    return () => {
      let selections = [...tagsSelection];

      if (selections.includes(tag)) {
        selections = selections.filter((selection) => selection !== tag);
      } else {
        selections.push(tag);
      }
      handleTagsChange(selections);
    };
  }

  function handleSaveNewTag(newTag: string) {
    if (!newTag) return;

    setNewTags([...newTags, newTag]);
    handleTagsChange([...tagsSelection, newTag]);
  }

  function tagsListWithAddedTags() {
    if (tagSearch) {
      return [...newTags, ...tagList].filter((tag) => tag.toLocaleLowerCase().includes(tagSearch.toLocaleLowerCase()));
    }

    return [...newTags, ...tagList];
  }

  const tags = tagsListWithAddedTags();

  return {
    tagList: tags,
    tagsSelection,
    hasMore,
    isPending,
    handleTagSelection,
    handleSaveNewTag,
    handleTagSearchChange,
    loadMore,
  };
}
