import { useToggleState } from 'Utils';
import { ReactNode, useEffect, useRef, useState } from 'react';
interface ShowMoreItemsProps {
  children: ReactNode[];
  minItems: number;
}

export default function ShowMoreItems(props: ShowMoreItemsProps) {
  const { children, minItems } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {children.slice(0, showMore ? children.length : minItems)}
      {children.length > minItems && (
        <a className="link cursor-pointer" onClick={() => setShowMore((s) => !s)}>
          {showMore ? 'Show less' : 'Show more'}
        </a>
      )}
    </>
  );
}

interface ShowMoreByHeightProps {
  children: ReactNode;
  height: number;
}

export function ShowMoreByHeight(props: ShowMoreByHeightProps) {
  const { children, height } = props;
  const [showMore, setShowMore] = useState(false);
  const showToggle = useToggleState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;

    if (contentRef.current.clientHeight > height) showToggle.toggleOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current, height]);

  const style = showToggle.isToggled ? { height: showMore ? 'auto' : height } : undefined;

  return (
    <>
      <div className="overflow-hidden" ref={contentRef} style={style}>
        {children}
      </div>
      {showToggle.isToggled && (
        <a className="link cursor-pointer" onClick={() => setShowMore((s) => !s)}>
          {showMore ? 'Show less' : 'Show more'}
        </a>
      )}
    </>
  );
}
