import { useState } from 'react';

interface ShowMoreTextProps {
  text: string;
  minCharacters: number;
}

export default function ShowMoreText(props: ShowMoreTextProps) {
  const { text = '', minCharacters } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <span>
      {text.slice(0, showMore ? text.length : minCharacters)}
      {text.length > minCharacters && (
        <>
          {showMore ? ' ' : '... '}
          <a className="link cursor-pointer" onClick={() => setShowMore((s) => !s)}>
            {showMore ? 'less' : 'more'}
          </a>
        </>
      )}
    </span>
  );
}
