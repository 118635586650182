import { atom, useRecoilValue, useRecoilState, AtomEffect, useSetRecoilState } from 'recoil';

import { getPersistentState, savePersistentState } from 'Utils';

const TENANT_ID_STORE = 'selectedTenantId';

const persistTenantId: AtomEffect<string> = ({ onSet }) => {
  onSet((filters) => {
    savePersistentState(TENANT_ID_STORE, filters);
  });
};

export const selectedTenantId = atom({
  key: 'selectedTenantId',
  default: getPersistentState(TENANT_ID_STORE),
  effects: [persistTenantId],
});

export function useSelectedTenantId() {
  return useRecoilValue(selectedTenantId);
}

export function useSelectedTenantIdState() {
  return useRecoilState(selectedTenantId);
}

export function useClearSelectedTenantId() {
  const setSelectedTenantId = useSetRecoilState(selectedTenantId);
  function clearTenantId() {
    setSelectedTenantId('');
  }
  return clearTenantId;
}
