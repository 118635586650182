export const sortByOptions = [
  {
    label: 'Created at (Default)',
    value: '-created_at',
  },
  {
    label: 'Control Status (Yes)',
    value: 'last_response__control_status',
  },
  {
    label: 'Control Status (No)',
    value: '-last_response__control_status',
  },
  {
    label: 'Risk Status (Acceptable)',
    value: 'last_response__risk_status',
  },
  {
    label: 'Risk Status (Not Acceptable)',
    value: '-last_response__risk_status',
  },
  {
    label: 'Last Request Date (Newest)',
    value: '-last_request__created_at',
  },
  {
    label: 'Last Request Date (Oldest)',
    value: 'last_request__created_at',
  },
  {
    label: 'Last Response Date (Newest)',
    value: '-last_response__updated_at',
  },
  {
    label: 'Last Response Date (Oldest)',
    value: 'last_response__updated_at',
  },
  {
    label: 'Risk Owner',
    value: 'risk_owner__user__attributes',
  },
];

export const sortByOutstandingOptions = [
  {
    label: 'Oustanding Verification (Newest)',
    value: '-outstanding_verification',
  },
  {
    label: 'Oustanding Verification (Oldest) ',
    value: 'outstanding_verification',
  },
];

export const defaultSortBy = sortByOptions[0].value;
