import { useCallback, useEffect, useReducer } from 'react';

type Action = () => void;
type Guard = () => boolean;

export interface Event {
  type: string;
  data?: unknown;
}

export interface StateAction {
  nextState: string;
  action?: Action;
  guard?: Guard;
}

interface CommonEvents {
  entry?: Action;
  exit?: Action;
}

export interface State {
  [event: Event['type']]: StateAction;
}

export interface States {
  [state: string]: State | CommonEvents;
}

export interface MachineStates {
  initialState: string;
  states: States;
}

type EventsOfUnionStates<T> = T extends T ? keyof Omit<T, keyof CommonEvents> : never;
type EventTypes<T extends MachineStates['states']> = EventsOfUnionStates<T[keyof T]>;
type StateTypes<T extends MachineStates> = keyof T['states'];

function stateTransitionReducer(states: States) {
  return (activeState: string, event: Event) => {
    const currentState = states[activeState];

    if (event.type in currentState) {
      const { nextState, action, guard } = (currentState as State)[event.type];

      if (guard && !guard()) {
        return activeState;
      }

      action?.();

      if (nextState === activeState) {
        return activeState;
      }

      (currentState as CommonEvents).exit?.();

      return nextState;
    }

    return activeState;
  };
}

export default function useStateMachine<T extends MachineStates>(machine: T) {
  const [activeState, dispatchEvent] = useReducer(stateTransitionReducer(machine.states), machine.initialState);

  const sendEvent = useCallback(
    (type: EventTypes<T['states']>, data?: unknown) => {
      dispatchEvent({ type: type as string, data });
    },
    [dispatchEvent]
  );

  useEffect(() => {
    (machine.states[activeState] as CommonEvents).entry?.();
  }, [activeState, machine.states]);

  return { activeState: activeState as StateTypes<typeof machine>, sendEvent };
}
