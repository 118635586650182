interface RemoveIconProps {
  handleRemove: () => void;
  variant?: 'light' | 'dark';
}

export default function RemoveIcon({ variant = 'dark', handleRemove }: RemoveIconProps) {
  return (
    <span className={`remove-icon ${variant}`} onClick={handleRemove}>
      <span className="fs-smaller-1">&#10006;</span>
    </span>
  );
}
