import { ReactNode } from 'react';
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader } from 'mdb-react-ui-kit';

type MDBPopoverProps = Parameters<typeof MDBPopover>[0];

export interface PopoverProps extends MDBPopoverProps {
  content: ReactNode;
  children: ReactNode;
  header?: string;
  closeOnClickOutside?: boolean;
}

export default function Popover(props: PopoverProps) {
  const { content, children, header, closeOnClickOutside, tag = 'span', btnClassName = 'cursor-pointer p-1', ...config } = props;

  return (
    <MDBPopover
      {...config}
      className="border border-dark rounded"
      tag={tag}
      btnClassName={btnClassName}
      btnChildren={children}
      dismiss={closeOnClickOutside}
    >
      <MDBPopoverHeader className="bg-light border-bottom">{header}</MDBPopoverHeader>
      <MDBPopoverBody className="p-0">{content}</MDBPopoverBody>
    </MDBPopover>
  );
}
