import { ReactNode } from 'react';

import RemoveIcon from './RemoveIcon';

interface RemovableBadgeProps {
  label: ReactNode;
  handleRemove: () => void;
}

export default function RemovableBadge({ label, handleRemove }: RemovableBadgeProps) {
  return (
    <div className="text-start gap-1 removable-badge position-relative">
      <div>{label}</div>
      <RemoveIcon variant='light' handleRemove={handleRemove}/>
    </div>
  );
}
