import { useCallback } from 'react';

import { useControlFiltersState, ControlFilters, ControlFilterList } from 'States';
import { UserInfo } from 'Types';
import { parseSingleUserValueFilter } from 'Utils';

import { defaultSortBy, sortByOutstandingOptions } from './ControlSortByModal/ControlSortByModal.config';

export default function useControlFilters() {
  const [controlFilters, setControlFilters] = useControlFiltersState();

  const setFilter = useCallback(
    (type: keyof ControlFilters | 'clearFilterList' | 'setFilterList', payload?: unknown) => {
      const newFilters = { ...controlFilters };
      if (type === 'page') {
        newFilters['page'] = payload as number;
      } else if (type === 'search') {
        newFilters['search'] = payload as string;
      } else if (type === 'include_archive') {
        newFilters['include_archive'] = payload as boolean;
      } else if (type === 'filterList') {
        const { key, value } = payload as { key: keyof ControlFilterList; value: string[] };
        const filterList = { ...controlFilters.filterList, [key]: value };
        newFilters['filterList'] = filterList;
      } else if (type === 'clearFilterList') {
        newFilters['filterList'] = {} as ControlFilterList;
      } else if (type === 'setFilterList') {
        newFilters['filterList'] = payload as ControlFilterList;
      } else {
        return;
      }

      // Any filter changes should reset the page to 1, to get the paginated filtered list
      if (type !== 'page') {
        newFilters['page'] = 1;
      }

      if (['filterList', 'clearFilterList', 'setFilterList'].includes(type)) {
        const filteredUser = parseSingleUserValueFilter(newFilters['filterList']);

        if (!filteredUser && sortByOutstandingOptions.some((option) => option.value === controlFilters.sort_by)) {
          newFilters['sort_by'] = defaultSortBy;
        }
      }

      setControlFilters(newFilters);
    },
    [controlFilters, setControlFilters]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setFilter('search', value);
    },
    [setFilter]
  );

  const handleFilterListChange = useCallback(
    (key: keyof ControlFilterList, value: string[] | UserInfo[] | undefined) => {
      setFilter('filterList', { key, value });
    },
    [setFilter]
  );

  const setCurrentPage = useCallback(
    (page: number) => {
      setFilter('page', page);
    },
    [setFilter]
  );

  const setFilterList = useCallback(
    (filterList: ControlFilterList) => {
      setFilter('setFilterList', filterList);
    },
    [setFilter]
  );

  const toggleArchive = useCallback(() => {
    setFilter('include_archive', !controlFilters?.include_archive);
  }, [controlFilters?.include_archive, setFilter]);

  function clearFilterList() {
    setFilter('clearFilterList');
  }

  const handleSortChange = useCallback(
    (value: string) => {
      const sortBy = value || defaultSortBy;
      setControlFilters((state) => ({ ...state, sort_by: sortBy, page: 1 }));
    },
    [setControlFilters]
  );

  const removeSortBy = useCallback(() => {
    setControlFilters((state) => ({ ...state, sort_by: defaultSortBy, page: 1 }));
  }, [setControlFilters]);

  return {
    controlFilters,
    toggleArchive,
    setCurrentPage,
    handleSearchChange,
    handleSortChange,
    handleFilterListChange,
    clearFilterList,
    setFilterList,
    removeSortBy,
  };
}
