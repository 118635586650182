import { Outlet } from 'react-router-dom';
import { useState } from 'react';

import { SideNav } from '@objectit/ui-lib-bootstrap';
import { MenuItems } from '@objectit/ui-lib-bootstrap/dist/modules/SideNav/SideNavMenu';

import { useCurrentUser } from 'Utils';
import TopNav from '../TopNav/TopNav';
import { ModifyStatusComponent } from '../../../pages';


const menuItems = [
  {
    label: 'Controls List',
    pathname: '/controls',
  },
  {
    label: 'Status History',
    pathname: '/status_history',
  }
];

export default function PageLayout() {
  const [isModifyStatusComponentShown, setIsModifyStatusComponentShown] = useState(false);
  const { isAdmin,  } = useCurrentUser();

  const handleClose = () => {
    setIsModifyStatusComponentShown(false);
  };

  const modifyStatusComponentProps = {
    isOpen: isModifyStatusComponentShown,
    handleClose: handleClose,
  };

  const sideNavItems = isAdmin
  ? [...menuItems, {
      label: 'Settings',
      action: () => {
        setIsModifyStatusComponentShown(true);
      }
    }]
  : menuItems as MenuItems;

  return (
    <div className="d-flex h-100 overflow-hidden">
      <div>
        <SideNav header="Critical Control System" menuItems={sideNavItems} />
      </div>
      <div className="w-100 d-grid" style={{ gridTemplateRows: 'max-content auto' }}>
        <TopNav />
        <Outlet />
        {isModifyStatusComponentShown && <ModifyStatusComponent {...modifyStatusComponentProps} />}
      </div>
    </div>
  );
}
