import { useGetStatusCategories } from 'Services';
import { useStatusCategoriesState } from 'States';
import { StatusCategoriesResponse } from 'Types';

export default function useFetchStatusCategories() {
  const [statusCategories, setStatusCategories] = useStatusCategoriesState();

  useGetStatusCategories({
    onMount: true,
    onSuccess: ({ response }) => {
      const categories = response as unknown as StatusCategoriesResponse[];
      setStatusCategories(categories?.map(({ id, attributes }) => ({ category_id: id, ...attributes })) || []);
    },
  });

  return statusCategories;
}
