import { RiskStatus } from 'Types';
import { RISK_STATUSES, DEFAULT_RISK_STATUS } from 'Utils';

interface RiskStatusProps {
  riskStatus: RiskStatus;
  size?: number | string;
  fontSize?: number | string;
}

export default function RiskStatusIcon(props: RiskStatusProps) {
  const { riskStatus = DEFAULT_RISK_STATUS, size = 36 } = props;
  const statusColor = RISK_STATUSES[riskStatus].color;
  const style = { width: size };

  return (
    <div className="d-flex position-relative" style={style}>
      <svg viewBox="0 0 24 24" color={statusColor} xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="2" fill="currentColor" />
        <path
          d="M7.76136 18V6.36364H12.3523C13.2311 6.36364 13.9811 6.52083 14.6023 6.83523C15.2273 
          7.14583 15.7027 7.58712 16.0284 8.15909C16.358 8.72727 16.5227 9.39583 
          16.5227 10.1648C16.5227 10.9375 16.3561 11.6023 16.0227 12.1591C15.6894 
          12.7121 15.2064 13.1364 14.5739 13.4318C13.9451 13.7273 13.1837 13.875 
          12.2898 13.875H9.21591V11.8977H11.892C12.3617 11.8977 12.7519 11.8333 
          13.0625 11.7045C13.3731 11.5758 13.6042 11.3826 13.7557 11.125C13.911 
          10.8674 13.9886 10.5473 13.9886 10.1648C13.9886 9.77841 13.911 9.45265 
          13.7557 9.1875C13.6042 8.92235 13.3712 8.72159 13.0568 8.58523C12.7462 
          8.44508 12.3542 8.375 11.8807 8.375H10.2216V18H7.76136ZM14.0455 12.7045L16.9375 18H14.2216L11.392 12.7045H14.0455Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
