import { ReactNode } from 'react';
import { MDBTooltip } from 'mdb-react-ui-kit';

type MDBTooltipProps = Parameters<typeof MDBTooltip>[0];

export interface TooltipProps extends MDBTooltipProps {
  tooltip: ReactNode;
  className?: string;
}

export default function Tooltip(props: TooltipProps) {
  const { tooltip, children, className, tag = 'div', ...otherProps } = props;

  return (
    <MDBTooltip {...otherProps} tag={tag} className={className} title={tooltip}>
      {children}
    </MDBTooltip>
  );
}
