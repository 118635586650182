import { useCallback, useRef, useState } from 'react';

export default function useUndoRedo<T>(intialData?: T) {
  const [stack, setStack] = useState<T[]>(() => {
    if (intialData) return [intialData];
    return [] as T[];
  });
  const currentIndex = useRef(-1);

  const push = useCallback(
    (data: T) => {
      const newStack = stack.slice(0, currentIndex.current + 1);
      newStack.push(data);
      setStack(newStack);
      currentIndex.current++;
    },
    [stack]
  );

  function undo() {
    if (currentIndex.current > 0) currentIndex.current--;

    return stack.at(currentIndex.current);
  }

  function redo() {
    if (stack.length - 1 > currentIndex.current) currentIndex.current++;

    return stack.at(currentIndex.current);
  }

  const current = stack.at(currentIndex.current);

  return { current, stack, push, undo, redo };
}
