import useDashboard from './Dashboard.controller';

import './Dashboard.css';

interface StatusDataProps {
  label: string;
  value: number;
  color: string;
}

interface StatusContainerProps {
  label: string;
  items: { label: string; value: number }[];
}

function StatusData(props: StatusDataProps) {
  const { label, value, color } = props;

  return (
    <div className="hstack gap-1">
      <span className={'badge bg-' + color}>{value}</span>
      <span className="text-nowrap">{label}</span>
    </div>
  );
}

function StatusContainer(props: StatusContainerProps) {
  const { label, items } = props;

  return (
    <div className="status-container">
      <div className="text-nowrap fw-bold mb-1">{label}</div>
      {items.map((status) => (
        <StatusData key={status.label} {...status} color={status.label.toLocaleLowerCase().replace(' ', '-')} />
      ))}
    </div>
  );
}

export default function Dashboard() {
  const { controlStatuses, riskStatuses } = useDashboard();

  return (
    <div className="hstack gap-2 px-2 py-1">
      <StatusContainer label="Control Status" items={controlStatuses} />
      <StatusContainer label="Risk Status" items={riskStatuses} />
    </div>
  );
}
