import { useEffect } from 'react';

import { Select } from '@objectit/ui-lib-bootstrap';
import { getAuthSession } from '@objectit/utils';

import { useControlFiltersState, useSelectedTenantIdState, ControlFilters } from 'States';
import { useRefreshTokenRequest } from 'Services';
import { useAuthProvider } from '@objectit/auth-provider';

interface Tenant {
  name: string;
  id: string;
}

interface TokenClaims {
  tenants: Tenant[];
  selected_tenant_id: string;
}

interface TenantOption {
  label: string;
  value: string;
}

function useTenantSelect() {
  const [selectedTenantId, setSelectedTenantId] = useSelectedTenantIdState();
  const [, setControlFilters] = useControlFiltersState();
  const { isSuccess, sendRequest: refreshToken } = useRefreshTokenRequest();
  const { getTokenClaims } = useAuthProvider();
  const { tenants, selected_tenant_id } = (getTokenClaims() as unknown as TokenClaims) || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tenantOptions: TenantOption[] = tenants?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  }) || [];

  useEffect(() => {
    let tenantId = selected_tenant_id ? selected_tenant_id : tenantOptions?.[0]?.value;

    if (!tenantOptions.some(({ value }) => value === tenantId)) {
      tenantId = tenantOptions[0]?.value;
    }
    setSelectedTenantId(tenantId);
  }, [refreshToken, selected_tenant_id, setSelectedTenantId, tenantOptions]);

  function onSelectTenant(_: unknown, value: unknown) {
    setSelectedTenantId(value as string);

    if (value !== selectedTenantId) {
      const { refresh } = getAuthSession();
      const data = {
        refresh,
        tenant_id: value,
      };
      refreshToken(data);
    }
  }

  useEffect(() => {
    if (!isSuccess) return;

    setControlFilters((filters: ControlFilters) => ({ ...filters, page: 1 }));
  }, [isSuccess, setControlFilters]);

  return { selectedTenantId, tenantOptions, onSelectTenant };
}

export default function TenantSelect() {
  const { selectedTenantId, tenantOptions, onSelectTenant } = useTenantSelect();

  return (
    <div className="float-right">
      <Select name="tenant" options={tenantOptions} value={selectedTenantId} onChange={onSelectTenant} />
    </div>
  );
}
