import { useCallback, useReducer } from 'react';

interface PaginatedData {
  page: number;
  pages: number;
}

type UsePaginationProps = PaginatedData;

type SetPagesAction = { type: 'SET_PAGES'; pages: number };
type NextPageAction = { type: 'NEXT_PAGE' };
type ResetAction = { type: 'RESET' };
type PaginatedAction = SetPagesAction | NextPageAction | ResetAction;

function paginationReducer(state: PaginatedData, action: PaginatedAction) {
  if (action.type === 'SET_PAGES') {
    return { ...state, pages: action.pages };
  } else if (action.type === 'NEXT_PAGE') {
    return { ...state, page: state.page + 1 };
  } else if (action.type === 'RESET') {
    return { data: [], page: 1, pages: 1 };
  }

  return state;
}

export default function usePagination(props: UsePaginationProps) {
  const [{ page, pages }, dispatch] = useReducer(paginationReducer, props);

  const nextPage = useCallback(() => {
    if (page >= pages) return;

    dispatch({ type: 'NEXT_PAGE' });
  }, [page, pages]);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  const setPages = useCallback((newPages: number) => {
    dispatch({ type: 'SET_PAGES', pages: newPages });
  }, []);

  const hasMore = page < pages;

  return { page, pages, hasMore, nextPage, setPages, reset };
}
