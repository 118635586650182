import { atom, useRecoilState } from 'recoil';

export const newTagsState = atom<string[]>({
  key: 'newTags',
  default: [],
});

export function useNewTagsState() {
  return useRecoilState(newTagsState);
}
