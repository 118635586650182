import { ControlStatusIcon, RiskStatusIcon, Tooltip } from 'Components';
import { useStatusCategoriesState } from 'States';
import { VerificationResponse } from 'Types';
import { CONTROL_STATUSES, DEFAULT_CONTROL_STATUS, DEFAULT_RISK_STATUS, RISK_STATUSES } from 'Utils';

interface ResponseStatusesProps {
  response: VerificationResponse;
  size?: number;
}

export default function ResponseStatuses({ response, size = 24 }: ResponseStatusesProps) {
  const { control_status = DEFAULT_CONTROL_STATUS, risk_status = DEFAULT_RISK_STATUS } = response || {};
  const [statusCategories] = useStatusCategoriesState();
  const controlStatusName = statusCategories?.find(({ status_type }) => status_type === 'control_status')?.name || '';
  const riskStatusName = statusCategories?.find(({ status_type }) => status_type === 'risk_status')?.name || '';
  const controlStatusLabel = CONTROL_STATUSES[control_status].label;
  const riskStatusLabel = RISK_STATUSES[risk_status].label;

  return (
    <div className="hstack gap-1">
      <Tooltip tooltip={`${controlStatusName} - ${controlStatusLabel}`}>
        <ControlStatusIcon controlStatus={control_status} size={size} />
      </Tooltip>
      <Tooltip tooltip={`${riskStatusName} - ${riskStatusLabel}`}>
        <RiskStatusIcon riskStatus={risk_status} size={size} />
      </Tooltip>
    </div>
  );
}
