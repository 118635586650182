import { useToggleState, useUnmount } from 'Utils';

import { TagsProps } from './Tags';
import { useNewTagsState } from './Tags.states';

type TagsSelection = TagsProps["tagsSelection"]

export default function useTags(tagsSelection: TagsSelection) {
  const { isToggled, toggleOn, toggleOff } = useToggleState();
  const [, setNewTags] = useNewTagsState();

  useUnmount(() => setNewTags([] as string[]));

  return { tags: tagsSelection, isToggled, toggleOn, toggleOff };
}
