import { useCallback, useRef } from 'react';

import useStateMachine from './useStateMachine';

export default function useDebounce(msTimeout: number) {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef<() => void>();

  function cancelTimeout() {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }

  function start() {
    cancelTimeout();
    timeout.current = setTimeout(() => {
      callback.current?.();
    }, msTimeout);
  }

  const { activeState, sendEvent } = useStateMachine({
    initialState: 'idle',
    states: {
      idle: {
        entry: cancelTimeout,
        START: {
          nextState: 'started',
          action: start,
        },
      },
      started: {
        START: {
          nextState: 'started',
          action: start,
        },
        CANCEL: { nextState: 'idle' },
        DONE: { nextState: 'done' },
      },
      done: {
        START: {
          nextState: 'started',
          action: start,
        },
      },
    },
  });

  const debounce = useCallback(
    (callbackFn: () => void) => {
      callback.current = () => {
        callbackFn();
        sendEvent('DONE');
      };
      sendEvent('START');
    },
    [sendEvent]
  );

  function cancelDebounce() {
    sendEvent('CANCEL');
  }

  return { isStarted: activeState === 'started', isDone: activeState === 'done', debounce, cancelDebounce };
}
