interface Statuses {
  [key: number]: {
    label: string;
    color: string;
  };
}

export const CONTROL_STATUSES: Statuses = {
  1: { label: 'NA', color: 'var(--status-na)' },
  2: { label: 'Yes', color: 'var(--status-yes)' },
  3: { label: 'Mostly', color: 'var(--status-mostly)' },
  4: { label: 'Partly', color: 'var(--status-partly)' },
  5: { label: 'No', color: 'var(--status-no)' },
  6: { label: 'No Response', color: 'var(--status-no-response)' },
};

export const RISK_STATUSES: Statuses = {
  1: { label: 'NA', color: 'var(--status-na)' },
  2: { label: 'Acceptable', color: 'var(--status-acceptable)' },
  3: { label: 'Tolerable', color: 'var(--status-tolerable)' },
  4: { label: 'Not Acceptable', color: 'var(--status-not-acceptable)' },
  5: { label: 'No Response', color: 'var(--status-no-response)' },
};

export const DEFAULT_CONTROL_STATUS = 6;
export const DEFAULT_RISK_STATUS = 5;
