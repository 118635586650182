import { useCallback, useEffect, useReducer } from 'react';

import { Pagination } from '@objectit/utils/dist/services/useRequest/middleware/ApiMiddleware';

interface UsePaginatedFetchProps {
  isSuccess: boolean;
  response: unknown;
  pagination?: Pagination;
  dataParser?: (response: unknown[]) => unknown[];
}

interface PaginatedData {
  data: unknown[];
  page: number;
  pages: number;
  total: number;
}

const DEFAULT_PAGINATED_DATA = { data: [], page: 1, pages: 1, total: 0 };

type SetDataAction = { type: 'SET_DATA'; data: unknown[]; pages: number; total: number };
type NewDataAction = { type: 'NEW_DATA'; data: unknown[]; page?: number };
type NextPAgeAction = { type: 'NEXT_PAGE' };
type ResetAction = { type: 'RESET' };
type PaginatedAction = SetDataAction | NewDataAction | NextPAgeAction | ResetAction;

function paginatedFetchReducer(state: PaginatedData, action: PaginatedAction) {
  if (action.type === 'SET_DATA') {
    return { ...state, data: action.data, pages: action.pages, total: action.total };
  } else if (action.type === 'NEW_DATA') {
    return { ...state, data: state.data.concat(action.data) };
  } else if (action.type === 'NEXT_PAGE') {
    return { ...state, page: state.page + 1 };
  } else if (action.type === 'RESET') {
    return DEFAULT_PAGINATED_DATA;
  }

  return state;
}

export default function usePaginatedFetch(props: UsePaginatedFetchProps) {
  const { isSuccess, response, pagination, dataParser } = props;
  const [{ data, page, pages, total }, dispatch] = useReducer(paginatedFetchReducer, DEFAULT_PAGINATED_DATA);

  useEffect(() => {
    if (!isSuccess) return;

    const newData = dataParser ? dataParser(response as unknown[]) : (response as unknown[]);

    if (page === 1) {
      dispatch({
        type: 'SET_DATA',
        data: newData || [],
        pages: pagination?.pages || 1,
        total: pagination?.count || newData?.length || 0,
      });
    } else {
      dispatch({
        type: 'NEW_DATA',
        data: newData || [],
        page: pagination?.pages || page,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isSuccess]);

  const loadMore = useCallback(() => {
    if (page >= pages) return;

    dispatch({ type: 'NEXT_PAGE' });
  }, [page, pages]);

  function resetPagination() {
    dispatch({ type: 'RESET' });
  }

  const hasMore = page < pages;

  return { data, page, pages, total, hasMore, loadMore, resetPagination };
}
