import { useRef } from 'react';
import ReactMoment from 'react-moment';

interface DatePicker {
  name: string;
  onChange: (name: string, value: string) => void;
  value?: string;
  minDate?: string;
  maxDate?: string;
}

function useDatePicker() {
  const dateInputRef = useRef<HTMLInputElement & { showPicker: () => void }>(null);

  function showPicker() {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  }

  return { dateInputRef, showPicker };
}

export default function DatePicker(props: DatePicker) {
  const { dateInputRef, showPicker } = useDatePicker();

  return (
    <>
      <button className="date-picker form-control text-start d-flex justify-content-between w-100" onClick={showPicker}>
        {props.value ? <ReactMoment format="DD MMM YYYY">{props.value}</ReactMoment> : 'dd mmm yyyy'}
        <span className="">
          <i className="bi bi-calendar"></i>
        </span>
      </button>
      <input
        className="form-control visually-hidden"
        type="date"
        id={props.name}
        name={props.name}
        value={props.value}
        ref={dateInputRef}
        onChange={({ target: { value } }) => props.onChange(props.name, value)}
        min={props.minDate}
        max={props.maxDate}
      ></input>
    </>
  );
}
