import { ReactElement } from 'react';
import { MDBBadge } from 'mdb-react-ui-kit';

import { RemovableBadge } from 'Components';
import { ControlFilterList } from 'States';
import { ComponentChildren, UserInfo } from 'Types';

import ControlFilterLabel from './ControlFilterLabel';
import useControlFilters from './ControlFilters.controller';

type UseControlFilters = ReturnType<typeof useControlFilters>;

interface ControlFilterSelectionsProps {
  filterList: ControlFilterList;
  handleFilterListChange: UseControlFilters['handleFilterListChange'];
  clearFilterList: UseControlFilters['clearFilterList'];
}

function userInfoToFilterTag(user: UserInfo) {
  return `${user.first_name} ${user.last_name} (${user.email})`;
}

function hasFilters(filters?: ControlFilterList) {
  if (!filters) {
    return false;
  }

  const filterValues = Object.values(filters);

  if (!filterValues.length) {
    return false;
  }

  return !!filterValues.reduce((result, filter) => result || filter?.length);
}

function removeFilterForControlStatus(handleFilterListChange: ControlFilterSelectionsProps['handleFilterListChange']) {
  return () => handleFilterListChange('control_statuses', undefined);
}

function removeFilterForRiskStatus(handleFilterListChange: ControlFilterSelectionsProps['handleFilterListChange']) {
  return () => handleFilterListChange('risk_statuses', undefined);
}

function removeFilterForTags(handleFilterListChange: ControlFilterSelectionsProps['handleFilterListChange']) {
  return () => handleFilterListChange('tags', undefined);
}

function removeFilterForRiskOwner(handleFilterListChange: ControlFilterSelectionsProps['handleFilterListChange']) {
  return () => handleFilterListChange('risk_owners', undefined);
}

function removeFilterForVerifiers(handleFilterListChange: ControlFilterSelectionsProps['handleFilterListChange']) {
  return () => handleFilterListChange('verifiers', undefined);
}

function ControlFilterAndBetweenSelections({ children }: ComponentChildren) {
  const selections = [] as ReactElement[];
  const filters = (children as ReactElement[]).filter((item) => !!item);

  filters.forEach((filter, index: number) => {
    selections.push(filter);
    if (index < filters.length - 1)
      selections.push(
        <MDBBadge className="control-filter-and" color="white">
          and
        </MDBBadge>
      );
  });

  return <>{selections}</>;
}

export default function ControlFilterSelections(props: ControlFilterSelectionsProps) {
  const { filterList, handleFilterListChange, clearFilterList } = props;
  const { control_statuses, risk_statuses, tags, risk_owners, verifiers } = filterList;

  return (
    <div className="col control-filter">
      Filtered by:
      <div className="hstack flex-wrap gap-1">
        <ControlFilterAndBetweenSelections>
          {risk_owners?.length && (
            <RemovableBadge
              key="risk_owners"
              label={<ControlFilterLabel label="Risk Owner" items={risk_owners.map(userInfoToFilterTag)} />}
              handleRemove={removeFilterForRiskOwner(handleFilterListChange)}
            />
          )}
          {verifiers?.length && (
            <RemovableBadge
              key="verifiers"
              label={<ControlFilterLabel label="Verifiers" items={verifiers.map(userInfoToFilterTag)} />}
              handleRemove={removeFilterForVerifiers(handleFilterListChange)}
            />
          )}
          {control_statuses?.length && (
            <RemovableBadge
              key="control_statuses"
              label={<ControlFilterLabel label="Control Status" items={control_statuses} />}
              handleRemove={removeFilterForControlStatus(handleFilterListChange)}
            />
          )}
          {risk_statuses?.length && (
            <RemovableBadge
              key="risk_statuses"
              label={<ControlFilterLabel label="Risk Status" items={risk_statuses} />}
              handleRemove={removeFilterForRiskStatus(handleFilterListChange)}
            />
          )}
          {tags?.length && (
            <RemovableBadge
              key="tags"
              label={<ControlFilterLabel label="Tags" items={tags} />}
              handleRemove={removeFilterForTags(handleFilterListChange)}
            />
          )}
        </ControlFilterAndBetweenSelections>
        {hasFilters(filterList) && (
          <a className="link text-decoration-underline" onClick={clearFilterList}>
            Clear Filters
          </a>
        )}
      </div>
    </div>
  );
}
