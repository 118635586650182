import { useAuthProvider } from '@objectit/auth-provider';
import { TokenClaims } from '@objectit/utils/dist/services/authService';

import { useSelectedTenantId } from '../states';

interface User extends TokenClaims {
  last_name: string;
}

export default function useCurrentUser() {
  const tenantId = useSelectedTenantId();
  const { getTokenClaims } = useAuthProvider();
  const { tenants, ...user } = getTokenClaims() as User;

  function isUserAdmin() {
    const selectedTenant = tenants?.find(({ id }) => tenantId === id) || {};
    return (selectedTenant as { is_admin: boolean }).is_admin;
  }

  const isAdmin = isUserAdmin();

  return { isAdmin, uid: user.user_id, ...user };
}
